export const TYPESTATU = {
  PHARMA : localStorage.getItem("setup") || '',
  HRCORE : 'HR Core', // HR CORE
  NONFF : 'NON FF' // HR CORE
}

export enum DivisionOrg {
  PHARMA = 'pharmaOrg',
  HRCORE = 'hrCoreOrg' ,// HR CORE
  NONFF = 'NonFf' // HR CORE
}
